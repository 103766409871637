import React from 'react';

import Map from './flywhere/Map.js';
import Disclaimer from './flywhere/Disclaimer.js';
import Menu from './flywhere/Menu.js';

class Application extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mapStyle: {
                name: "Dark",
                slug: "navigation-guidance-night-v2",
                default: true
            },
            isActiveNFZShown: false
        }
        this.setMapStyle = this.setMapStyle.bind(this)
        // this.handleMapMove = this.handleMapMove.bind(this)
        this.handleRuleState = this.handleRuleState.bind(this)
        this.handleToggleRule = this.handleToggleRule.bind(this)
        this.handleToggleActiveNFZ = this.handleToggleActiveNFZ.bind(this)
        this.handleMapRef = this.handleMapRef.bind(this)
    }

    // componentDidUpdate(prevProps, prevState) {

    // }

    // componentDidMount() {

    // }
    
    setMapStyle(style) {
        this.setState({ mapStyle: style })
    }
    handleToggleActiveNFZ(){
        this.setState({isActiveNFZShown: !this.state.isActiveNFZShown}, function(){
            // Map.forceUpdate()
        })
    }
    handleRuleState(ruleset, guideline, region) {
        this.setState({ region, ruleset, guideline })
    }
    handleToggleRule(ruleData) {
        var _this = this;
        var prevRuleset = [].concat(this.state.ruleset);
        var ruleset = prevRuleset.map((rule) => {
            if (rule.data === ruleData) {
                rule.active = !rule.active
                _this.state.map.setLayoutProperty(`${_this.state.region.ISO3166A3}:${rule.data}`, 'visibility', rule.active ? 'visible' : 'none');
                _this.state.map.setLayoutProperty(`${_this.state.region.ISO3166A3}:${rule.data}-line`, 'visibility', rule.active ? 'visible' : 'none');
            }
            return rule
        })
        this.setState({ ruleset })
    }
    handleMapRef(map) {
        this.setState({ map })
    }
    render() {
        return (
            <div id="app">
                <Disclaimer primary="Disclaimer" secondary="This map utility is provided as is, with absolutely no warranty expressed or implied. Any use is at your own risk."></Disclaimer>
                <Map
                    tokenMapBox={this.props.tokenMapBox}
                    mapStyle={this.state.mapStyle}
                    tokenFlywhere={this.props.tokenFlywhere}
                    rules={this.handleRuleState}
                    mapRef={this.handleMapRef}
                    isActiveNFZShown={this.state.isActiveNFZShown}
                ></Map>
                <Menu
                    className="flywhere-menu"
                    tokenMapBox={this.props.tokenMapBox}
                    tokenFlywhere={this.props.tokenFlywhere}
                    setMapStyle={this.setMapStyle}
                    mapStyle={this.state.mapStyle}
                    region={this.state.region}
                    guideline={this.state.guideline}
                    ruleset={this.state.ruleset}
                    handleToggleRule={this.handleToggleRule}
                    handleToggleActiveNFZ={this.handleToggleActiveNFZ}
                    isActiveNFZShown={this.state.isActiveNFZShown}
                ></Menu>
            </div>
        )
    }
}

export default Application