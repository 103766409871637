import React from 'react';

class BrandingControl {
    onAdd(map){
        this.map = map
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl';
        this.container.innerHTML = `
                <a class="mapboxgl-ctrl-dronexchange-logo" target="_blank" rel="noopener nofollow" href="https://dronexchange.sg/" aria-label="DroneXchange logo"></a>`;
        this.container.style.cursor = 'pointer';
        
        return this.container;
    }
}

class ShareControl {
    onAdd(map){
        this.map = map
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this.container.innerHTML = `
        <button class="mapboxgl-ctrl-icon mapboxgl-ctrl-share material-icons-round" type="button" aria-label="Share" aria-pressed="false">share</button></div>`
        this.container.addEventListener('click', ()=>{
            navigator.share({
                title: document.title,
                text: document.title,
                url: window.location.href
            });
            
        });

        return this.container;
    }
}


class DownloadAppControl {
    onAdd(map){
        this.map = map
        // this.app = new App()
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this.container.innerHTML = `<a href="https://dronexchange.sg/app/download" target="_blank">
        <button class="mapboxgl-ctrl-icon mapboxgl-ctrl-app-qr material-icons-round" type="button" aria-label="Share" aria-pressed="false">
            get_app
        </button></a>`
        
        return this.container;
    }
}
class AppQRControl {
    onAdd(map){
        this.map = map
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl';
        this.container.innerHTML = `
                <a class="mapboxgl-ctrl-dronexchange-app" target="_blank" rel="noopener nofollow" href="https://dronexchange.sg/app/download" aria-label="DroneXchange Download App logo"></a>`;
        this.container.style.cursor = 'pointer';
        return this.container;
    }
    
}

export {
    BrandingControl,
    ShareControl,
    DownloadAppControl,
    AppQRControl,
  }