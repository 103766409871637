import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import post from './vendor/post'
import CircularProgress from '@material-ui/core/CircularProgress';

import Theme from './vendor/flywhere-theme';

import Application from './components/Application'

class ViewRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: {
        mapbox: 'pk.eyJ1Ijoiam9yZGFubHlzOTUiLCJhIjoiY2pwdGY1cnJvMDBybTQycHVkNDVzMGptNyJ9.D6g_-JlWkYhZMt0GMUxTrA'
      },
    };
    this.getToken = this.getToken.bind(this);
    this.verifyToken = this.verifyToken.bind(this);
  }
  componentDidMount() {
    let _this = this;
    this.getToken();
    
  }

  verifyToken(token) {
    fetch('/auth/verify', {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
    }).then(response => {
      return response.json()
    }).then(data => {
      // console.log(data)
      this.setState({
        tokenExpiry: data.exp
      }, () => {
        var intTick = setInterval(this.tick, 1000);
        if (this.state.intTick) {
          clearInterval(this.state.intTick);
        }
        this.setState({ intTick: intTick })
      })
    })
  }
  getToken() {
    // let _this = this;
    // if (this.state.intTick) {
    //   clearInterval(this.state.intTick);
    // }
    // var intTick = setInterval(()=>{
    //   if(_this.state.tokenExpiry){
    //     console.log(Date.now()/1000, _this.state.tokenExpiry)

    //     if (Date.now / 1000 >= _this.state.tokenExpiry) {
    //       _this.getToken()
    //     }

    //   }
    // }, 1000);
    // this.setState({intTick})
    post('/auth/', { kid: 'E9eI1CNC36rcAj7pdH1VbjhRfkPt9MXa' })
      .then((response, err) => {
        var token = response.token;
        var stateToken = this.state.token;
        // console.log(token)
        stateToken['flywhere'] = token;
        this.setState({ token: stateToken })
        this.verifyToken(token)
      })
      .catch(error => console.error(error));

  }
  render() {
    return (
      <Router>
        <div>
          {/* <nav>
              <ul>
                <li>
                  <Link to="/">Map</Link>
                </li>
              </ul>
            </nav> */}

          {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/">
              <Theme>
                {!this.state.token.flywhere &&
                  <div style={{
                    display: 'flex',
                    height: '100vh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <CircularProgress disableShrink color="primary" />
                  </div>
                }
                {this.state.token.flywhere &&
                  <Application tokenFlywhere={this.state.token.flywhere} tokenMapBox={this.state.token.mapbox} />
                }
              </Theme>
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default ViewRouter;