import React from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import RegionControl from "./vendor/regionControl";
import PitchControl from "./vendor/pitchControl";
import IndicatorControl from "./vendor/indicatorControl";
import nfzListControl from "./vendor/nfzListControl";
import WeatherControl from './vendor/weatherControl';
import LinearProgress from "@material-ui/core/LinearProgress";
import {
    BrandingControl,
    ShareControl,
    DownloadAppControl,
    AppQRControl,
} from "./vendor/brandingControl";
import { polygon, featureCollection, booleanPointInPolygon } from '@turf/turf';

class Map extends React.Component {
    mapRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            innerHeight: window.innerHeight,
            geocoder: new MapboxGeocoder({
                language: window.navigator.userLanguage || window.navigator.language,
                accessToken: this.props.tokenMapBox,
                collapsed: true,
                clearAndBlurOnEsc: true,
                trackProximity: true,
                marker: false,
                countries: ["sg", "jp"].join(","),
            }),
            geolocate: new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                trackUserLocation: true,
                showUserLocation: false,
            }),
            lng: 105.4845,
            lat: 1.9684,
            zoom: 1,
            region: {},
            pointInNFZ: false,
        };
        this.fetchRuleset = this.fetchRuleset.bind(this);
        this.addMapSource = this.addMapSource.bind(this);
        this.updateMapSource = this.updateMapSource.bind(this);
        this.getLastSymbolLayer = this.getLastSymbolLayer.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        var _this = this;
        if (this.props.mapStyle.slug !== prevProps.mapStyle.slug) {
            this.state.map.setStyle(
                "mapbox://styles/mapbox/" + this.props.mapStyle.slug
            );
            this.state.ruleset.forEach((rule) => {
                _this.addMapSource(_this.state.region.ISO3166A3, rule);
            });
        }
        if (this.state.region.ISO3166A3 !== prevState.region.ISO3166A3) {
            this.state.ruleset.forEach((rule) => {
                _this.addMapSource(_this.state.region.ISO3166A3, rule);
            });
        }
        if(this.props.isActiveNFZShown !== prevProps.isActiveNFZShown){
            this.state.ruleset.forEach((rule) => {
                _this.updateMapSource(rule);
            });
        }
    }

    componentDidMount() {
        var _this = this;
        window.addEventListener("resize", function () {
            _this.setState({ innerHeight: window.innerHeight });
        });
        const countryBBOX = {
            SGP: { sw: { lat: 1.156, lng: 103.565 }, ne: { lat: 1.475, lng: 104.13 } }
        }
        const { SGP } = countryBBOX;
        const singaporeBBOX = polygon([
            [
                [SGP.sw.lng, SGP.ne.lat],
                [SGP.ne.lng, SGP.ne.lat],
                [SGP.ne.lng, SGP.sw.lat],
                [SGP.sw.lng, SGP.sw.lat],
                [SGP.sw.lng, SGP.ne.lat],
            ]
        ]);
        mapboxgl.accessToken = this.props.tokenMapBox;
        
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapRef.current,
            style: "mapbox://styles/mapbox/" + this.props.mapStyle.slug,
            center: [lng, lat],
            zoom,
            attributionControl: false,
            // hash: true
        });
        this.setState({ marker: this.createMapMarker() })
        this.setState({ map: map });
        this.props.mapRef(map);
        map.on("style.load", function () {
            _this.addMapBoundary(map);
            _this.add3DBuildingLayer(map);
        });
        map.on("load", () => {
            _this.addMapControls(map);
            map.flyTo({
                center: [103.8198, 1.3521],
                zoom: 10,
            });
            map.on("click", function (e) {
                map.flyTo({
                    center: [e.lngLat.lng, e.lngLat.lat],
                });
                _this.state.marker.setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map);
            });
            this.fetchRuleset();
        });
        map.on("move", ()=>{
            const { lng, lat } = map.getCenter();

            this.setState({
                lng: lng.toFixed(4),
                lat: lat.toFixed(4),
                zoom: map.getZoom().toFixed(2),
            });
            _this.state.marker.setLngLat([lng, lat]).addTo(map);

        });
        map.on("moveend", () => {
            const { lng, lat } = map.getCenter();
            
            if(booleanPointInPolygon([lng, lat], singaporeBBOX)){
                this.state.geocoder.setProximity({ longitude: lng, latitude: lat });
                this.setState({
                    lng: lng.toFixed(4),
                    lat: lat.toFixed(4),
                    zoom: map.getZoom().toFixed(2),
                });
                _this.state.marker.setLngLat([lng, lat]).addTo(map);
                // this.fetchRuleset();
            }
        });
    }
    fetchRuleset() {
        var _this = this;
        this.setState({ loading: true });
        fetch("/api/v2/area/ruleset", {
            method: "POST",
            headers: {
                "x-api-auth": _this.props.tokenFlywhere,
            },
            body: JSON.stringify({
                coords: {
                    lng: _this.state.lng,
                    lat: _this.state.lat,
                },
                bbox: _this.state.map.getBounds(),
            }),
        })
            .then((response) => response.json())
            .then(({ ruleset, guideline, region }) => {
                _this.props.rules(ruleset, guideline, region);
                _this.setState({ guideline });
                _this.setState({ loading: false });
                _this.setState({ ruleset }, () => {
                    _this.setState({ region });
                });
                _this.setState({ loading: false });
                // this.props.ruleState(ruleset)
            });
    }
    addMapBoundary(_map) {
        let _this = this;
        const countryBBOX = {
            SGP: { sw: { lat: 1.156, lng: 103.565 }, ne: { lat: 1.475, lng: 104.13 } },
            // JPN: { sw: { lat: 20.416667, lng: 122.933333 }, ne: { lat: 45.520833, lng: 154 } }
        }

        const { SGP, JPN } = countryBBOX;
        const bboxGeoJSON = polygon([
            [
                [-180, 90],
                [180, 90],
                [180, -90],
                [-180, -90],
                [-180, 90],
            ],
            [
                [SGP.sw.lng, SGP.ne.lat],
                [SGP.ne.lng, SGP.ne.lat],
                [SGP.ne.lng, SGP.sw.lat],
                [SGP.sw.lng, SGP.sw.lat],
                [SGP.sw.lng, SGP.ne.lat],
            ],
            // [
            //     [JPN.sw.lng, JPN.ne.lat],
            //     [JPN.ne.lng, JPN.ne.lat],
            //     [JPN.ne.lng, JPN.sw.lat],
            //     [JPN.sw.lng, JPN.sw.lat],
            //     [JPN.sw.lng, JPN.ne.lat],
            // ]
        ]);

        _map.addLayer(
            {
                id: "bbox",
                type: "fill",
                source: {
                    type: "geojson",
                    tolerance: 10,
                    buffer: 0,
                    data: bboxGeoJSON,
                },
                paint: {
                    "fill-color": "rgba(0,0,0,.5)",
                    "fill-antialias": false,
                },
            }
        );
    }
    addMapSource(region, rule) {
        var _this = this;
        var map = _this.state.map;
        fetch(`/api/v2/area/${region}/${rule.data}`, {
            method: "POST",
            headers: {
                "x-api-auth": this.props.tokenFlywhere,
            },
        })
            .then((response) => response.json())
            .then((geojson) => {
                var _geojson = geojson;
                _this.setState({['_data-'+rule.data]: geojson});
                if (typeof map.getSource(rule.data) === "undefined") {
                    this.state.map.addSource(rule.data, {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: [],
                        },
                    });
                }
                
                map.getSource(rule.data).setData(_geojson);
                if (typeof map.getLayer(`${region}:${rule.data}`) === "undefined") {
                    // map.flyTo({ center: [_this.state.lng, _this.state.lat] });
                    
                    map.addLayer(
                        {
                            id: `${region}:${rule.data}`,
                            type: "fill",
                            source: rule.data,
                            layout: {
                                visibility: rule.active ? "visible" : "none",
                            },
                            paint: {
                                "fill-opacity": 0.25,
                                "fill-color": rule.color,
                            }
                            // minzoom: 9,
                        },
                        rule.layerId ? rule.layerId : _this.getLastSymbolLayer(map)
                    );
                }
                if (
                    typeof map.getLayer(`${region}:${rule.data}-line`) === "undefined"
                ) {
                    map.addLayer(
                        {
                            id: `${region}:${rule.data}-line`,
                            type: "line",
                            source: rule.data,
                            layout: {
                                visibility: rule.active ? "visible" : "none",
                            },
                            paint: {
                                "line-width": 1,
                                "line-color": rule.color,
                            },
                            // minzoom: 9,
                        },
                        rule.layerId ? rule.layerId : _this.getLastSymbolLayer(map)
                    );
                }
            });
    }
    updateMapSource({data}){
        const prefix = '_data-';
        const rule_data_name = prefix + data;

        var rule_data_geojson = this.state[rule_data_name];
        console.log(rule_data_geojson)
        if(this.props.isActiveNFZShown){

            if(rule_data_geojson && typeof rule_data_geojson.features !== 'undefined'){
                var features = rule_data_geojson.features.filter(feature => {
                    var {properties} = feature;
                    if(typeof properties.validity === 'undefined'){
                        return feature
                    }
                    var now = Date.now();
                    var activeGeometry = properties.validity.filter(val => (now >= new Date(val.start).getTime() && now <= new Date(val.end).getTime())).length > 0 ? true : false;
                    if(activeGeometry){
                        return feature
                    }
                })
                rule_data_geojson = featureCollection(features);

            }

        }
        try{

            this.state.map.getSource(data).setData(rule_data_geojson);
        } catch (exception) {
            console.error(exception)
        }
    }
    addMapControls(_map) {
        _map
            // .addControl(new RegionControl(), "bottom-left")
            .addControl(this.state.geolocate, "bottom-left")
            .addControl(new IndicatorControl(), "top-left")
            .addControl(this.state.geocoder, "top-left")
            .addControl(new nfzListControl(), "top-right")
            .addControl(new mapboxgl.NavigationControl(), "bottom-left")
            .addControl(new PitchControl(), "bottom-left")
            .addControl(new BrandingControl(), "bottom-right")
            .addControl(new DownloadAppControl(), 'bottom-right')
            // .addControl(new AppQRControl(), 'bottom-right')
            .addControl(new ShareControl(), "bottom-right")
            // .addControl(new WeatherControl(), 'top-right')
    }
    createMapMarker() {
        var elMarker = document.createElement("div");
        elMarker.className = "marker";
        elMarker.style.backgroundImage = "url('/assets/img/marker.svg')";

        elMarker.style.width = "35px";
        elMarker.style.height = "35px";
        elMarker.style.backgroundRepeat = "no-repeat";

        var marker = new mapboxgl.Marker({
            anchor: "bottom",
            element: elMarker,
            draggable: true,
        });

        this.setState({ mapMarker: marker });
        return marker;
    }
    add3DBuildingLayer(_map) {
        var _this = this;
        _map.addLayer(
            {
                id: "3d-buildings",
                source: "composite",
                "source-layer": "building",
                filter: ["==", "extrude", "true"],
                type: "fill-extrusion",
                minzoom: 15,
                layout: {
                    visibility: "none",
                },
                paint: {
                    "fill-extrusion-color": "#aaa",

                    // use an 'interpolate' expression to add a smooth transition effect to the
                    // buildings as the user zooms in
                    "fill-extrusion-height": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        15,
                        0,
                        15.05,
                        ["get", "height"],
                    ],
                    "fill-extrusion-base": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        15,
                        0,
                        15.05,
                        ["get", "min_height"],
                    ],
                    "fill-extrusion-opacity": 0.6,
                },
            },
            _this.getLastSymbolLayer(_map)
        );
    }
    getLastSymbolLayer(_map) {
        // Get the layer beneath any symbol layer.
        var layers = _map.getStyle().layers;

        var labelLayerId;
        for (var i = 0; i < layers.length; i++) {
            if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
                labelLayerId = layers[i].id;
                break;
            }
        }
        return labelLayerId;
    }

    render() {
        const { lng, lat, zoom } = this.state;

        return (
            <div className="flywhere-map">
                <div
                    ref={this.mapRef}
                    style={{
                        height: this.state.innerHeight,
                    }}
                />
                {this.state.loading && <LinearProgress className="flywhere-loading" />}
            </div>
        );
    }
}

export default Map;
