import React from 'react';

import Paper from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Radio from '@material-ui/core/Radio';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

class MapStyle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listStyle:[
                {
                    name: "Dark",
                    slug: "navigation-guidance-night-v2",
                    default: true
                },
                {
                    name: "Light",
                    slug: "light-v10",
                    default: false
                },
                {
                    name: "Street",
                    slug: "streets-v11",
                    default: false
                },
                {
                    name: "Satellite",
                    slug: "satellite-streets-v11",
                    default: false
                }
            ]
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }
        
    componentDidMount() {
        
    }
    handleStyleChange(style){
        this.props.setMapStyle(style)
    }
    render() {
        return (
            <List subheader={<ListSubheader>Map Style</ListSubheader>} >
                {this.state.listStyle &&
                this.state.listStyle.map((style, key) => (
                    <ListItem 
                        onClick={(evt)=>{this.handleStyleChange(style)}} 
                        key={`map-style-list-item-${style.slug}`} 
                        button>
                        <ListItemAvatar>
                            <Avatar src={`https://api.mapbox.com/styles/v1/mapbox/${style.slug}/static/103.852,1.2835,14,0/150x150?access_token=${this.props.tokenMapBox}`}/>
                        </ListItemAvatar>
                        <ListItemText primary={style.name}/>
                        <ListItemSecondaryAction>
                            <Radio
                                name="map-style-list-item"
                                edge="end"
                                value={style.slug}
                                checked={this.props.mapStyle.slug === style.slug}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        )
    }
}

export default MapStyle