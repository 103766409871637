import React from "react";
import ReactDOM from "react-dom";

import ViewRouter from "./ViewRouter";
import './app.scss';

try
{
    // ReactDOM.render(<App/>, document.getElementById('root'));
    ReactDOM.render(<ViewRouter/>, document.getElementById('root'));
}
catch (err){
    console.log(err);
    gtag('event', 'exception', {
        'description': 'Ver.-' + navigator.platform + '-' + err.message,
        'fatal': false   // set to true if the error is fatal
    });
}