import React from 'react';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class Disclaimer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }
        
    componentDidMount() {
        
    }
    
    render() {
        return (
            <Card className="flywhere-disclaimer" >
                <List dense>
                    <ListItem>
                        {/* <ListItemAvatar>
                            <Avatar>
                                <Icon>priority_high</Icon>
                            </Avatar>
                        </ListItemAvatar> */}
                        <ListItemText
                            primary={this.props.primary}
                            secondary={ this.props.secondary ? this.props.secondary : null }
                        />
                        {/* <ListItemSecondaryAction>
                            <Icon>priority_high</Icon>
                        </ListItemSecondaryAction> */}
                    </ListItem>
                </List>
            </Card>
        )
    }
}

export default Disclaimer