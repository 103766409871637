
import {booleanPointInPolygon, point} from '@turf/turf';
class IndicatorControl{
    onAdd(map) {
        this._map = map;
        this._whitelist = ['nparks_parks', 'designated_drone', 'boundary_10km'];
        const container = document.createElement('div');
        container.className = 'mapboxgl-ctrl mapboxgl-ctrl-nfz-indicator--container';
        container.innerHTML = '<div class="mapboxgl-ctrl-nfz-indicator"><span class="material-icons">airplanemode_inactive</span><div>NFZ</div></div>';
        this._map.on('moveend', this.onMoveEnd.bind(this));
        this._container = container;
        return this._container;
    }
    onMoveEnd() {
        const { lng, lat } = this._map.getCenter();

        var center = point([lng, lat])
        var sources = this._map.getStyle().sources;
        var polygon_arr = [];
        // Excludes composite and bbox sources
        var excluded_sources = ['composite', 'bbox'];
        for(var s in sources){
            if(!excluded_sources.includes(s)){
                var features = sources[s].data.features;

                for(var f = 0; f < features.length; f++){
                    if(booleanPointInPolygon(center, features[f])){
                        if(!this._whitelist.includes(s)){
                            polygon_arr.push(s)
                        }
                    }

                }

            }
        }

        this._container.classList.toggle('active', (polygon_arr.length > 0));
    }
    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map.off('moveend', this.onMoveEnd.bind(this));
        this._map = undefined;
    }
};
export default IndicatorControl