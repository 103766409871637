import React from 'react';

import Paper from '@material-ui/core/Card';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';

import MapStyle from './MapStyle'
// import Rule from './rule'

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 0,
            drawerOpen: false,
            drawerAnchor: 'bottom',
            loading: false
        }
        this.toggleDrawer = this.toggleDrawer.bind(this)
        this.handleNavChange = this.handleNavChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentDidMount() {
        var _this = this;
        if (window.innerWidth <= 768) {
            _this.setState({ drawerAnchor: 'bottom' });

        } else {
            _this.setState({ drawerAnchor: 'left' });
        }
        window.addEventListener('resize', function () {
            if (window.innerWidth <= 640) {
                _this.setState({ drawerAnchor: 'bottom' });

            } else {
                _this.setState({ drawerAnchor: 'left' });
            }
        })

    }

    toggleDrawer(view, open) {
        return (event) => {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            this.setState({ drawerView: view })
            this.setState({ drawerOpen: open });
        }
    }
    handleNavChange(evt, value) {
        this.setState({ tabIndex: value })
    }
    processSwitchColor(rule){
        const CustomSwitch = withStyles({
            switchBase: {
              '&$checked': {
                color: rule.color,
              },
              '&$checked + $track': {
                backgroundColor: rule.color,
              },
            },
            checked: {},
            track: {},
          })(Switch);

        return <CustomSwitch checked={rule.active} />
    }
    render() {
        const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
        const darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        return (
            <div className="flywhere-menu" >
                <Paper square>
                    <BottomNavigation showLabels>
                        <BottomNavigationAction label="Map Filter" icon={<Icon>list</Icon>} onClick={this.toggleDrawer('ruleset', true)} />
                        <BottomNavigationAction label="Map Style" icon={<Icon>layers</Icon>} onClick={this.toggleDrawer('mapstyle', true)} />
                    </BottomNavigation>
                </Paper>
                <SwipeableDrawer
                    className="flywhere-drawer"
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    anchor={this.state.drawerAnchor}
                    open={this.state.drawerOpen}
                    onOpen={this.toggleDrawer(this.state.drawerView, true)}
                    onClose={this.toggleDrawer(this.state.drawerView, false)}>
                    <div className="drawer-content">
                        {this.state.drawerView === 'ruleset' &&
                            <List dense >
                                <ListSubheader style={{ backgroundColor: darkmode?'rgb(66, 66, 66)':'#fff', borderBottom: darkmode ?'1px solid rgba(49,49,49)' :'1px solid #eee' }}>Rules</ListSubheader>
                                {!this.props.ruleset &&
                                    <LinearProgress/>
                                }

                                {this.props.ruleset &&
                                    <ListItem onClick={(evt) => { this.props.handleToggleActiveNFZ() }} button>
                                        <ListItemIcon>
                                            {
                                                <Switch
                                                    checked={this.props.isActiveNFZShown}
                                                    onChange={this.props.handleToggleActiveNFZ}
                                                />
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary='Active Areas' secondary='Only show active NFZ at this time' />
                                    </ListItem>
                                }
                                {this.props.guideline &&
                                    this.props.guideline.map((guide, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={guide.title} secondary={guide.description} />
                                        </ListItem>
                                    ))
                                }

                                {this.props.ruleset &&
                                    this.props.ruleset.map((rule, index) => (
                                        <ListItem key={rule.data} onClick={(evt) => { this.props.handleToggleRule(rule.data) }} button>
                                            <ListItemIcon>
                                                {
                                                    this.processSwitchColor(rule)
                                                }
                                            </ListItemIcon>
                                            <ListItemText primary={rule.title} secondary={rule.description} />
                                        </ListItem>
                                    ))
                                }

                            </List>
                            }
                        {this.state.drawerView === 'mapstyle' &&
                            <MapStyle
                                tokenMapBox={this.props.tokenMapBox}
                                setMapStyle={this.props.setMapStyle}
                                mapStyle={this.props.mapStyle} />
                        }
                    </div>
                </SwipeableDrawer>
            </div>
        )
    }
}

export default Menu