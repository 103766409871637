
import {booleanPointInPolygon, point, polygon} from '@turf/turf';
class NfzListControl{
    onAdd(map) {
        this._map = map;
        // this._whitelist = ['nparks_parks', 'designated_drone', 'boundary_10km'];
        this._whitelist = [];
        const container = document.createElement('div');
        container.className = 'mapboxgl-ctrl mapboxgl-ctrl-nfz-list--container';
        container.innerHTML = `<button class="btn-dismiss-icon material-icons">close</button><div class="mapboxgl-ctrl-nfz-list"></div>`;
        container.querySelector('.btn-dismiss-icon').addEventListener('click',()=>{
            container.classList.toggle('active', false)
        })
        this._map.on('moveend', this.onMoveEnd.bind(this));
        this._container = container;
        return this._container;
    }
    onMoveEnd() {
        const { lng, lat } = this._map.getCenter();

        var center = point([lng, lat])
        var sources = this._map.getStyle().sources;
        var polygon_arr = [];
        // Excludes composite and bbox sources
        var excluded_sources = ['composite', 'bbox'];
        for(var s in sources){
            if(!excluded_sources.includes(s)){
                var features = sources[s].data.features;
                for(var f = 0; f < features.length; f++){
                    if(booleanPointInPolygon(center, features[f])){
                        if(!this._whitelist.includes(s)){
                            features[f].properties['source'] = s;
                            polygon_arr.push(features[f].properties)
                        }
                    }

                }

            }
        }

        this._container.classList.toggle('active', (polygon_arr.length > 0));
        
        this._container.querySelector('.mapboxgl-ctrl-nfz-list').innerHTML = polygon_arr.map(({name, description, source})=>(typeof name !== 'undefined')?`<div class="mapboxgl-ctrl-nfz-list--item ${source}">
            <div><strong>${name}</strong></div>
            ${description.length > 1 ? `<div>${description}</div>` : '<div>Allowed in most parks except parks with \'No Flying\' signs</div>'}
        </div>`:'').join("")
    }
    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map.off('moveend', this.onMoveEnd.bind(this));
        this._map = undefined;
    }
};
export default NfzListControl